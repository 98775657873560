<!-- <template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>Child Venue View</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item to="/manage_vendors" >View Vendors</b-breadcrumb-item>
          <b-breadcrumb-item :to="'/Manage_vendors/vendor_parent/'+vendor_id" >Parent Venue</b-breadcrumb-item>
          <b-breadcrumb-item :to="'/Manage_vendors/parent_venue/child_venue/'+parent_id+'/'+vendor_id" >Child Venue</b-breadcrumb-item>
          <b-breadcrumb-item active>View</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br>
      <b-card class="mb-4 w-100">
        <div class="text-left">
          <b-button :to="'/manage_vendors/parent_venue/'+vendor_id" variant="primary" class="mt-2"> Back </b-button>
          &nbsp;
        </div>
        <b-row>
          <b-col md="6">
            <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="lname2">Venue Name:</label>
              </b-col>
              <b-col sm="8">
                <span>
                    {{ child_venue_details.child_venue_name }}
                </span>
              </b-col>
            </b-row>
            <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="lname2">Location:</label>
              </b-col>
              <b-col sm="8">
                <span>
                  {{child_venue_details.child_venue_location}}
                </span>
              </b-col>
            </b-row>
  
            <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="nname2">Contact:</label>
              </b-col>
              <b-col sm="8">
                <span>
                  {{ child_venue_details.child_venue_contact }}
                </span>
              </b-col>
            </b-row>
  
           <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2"> Pincode:</label>
              </b-col>
              <b-col sm="8">
                <span>
                  {{ child_venue_details.child_venue_pincode }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="nname2">City:</label>
              </b-col>
              <b-col sm="8">
                <span>
                    {{ child_venue_details.child_venue_city }}
                </span>
              </b-col>
            </b-row>
            <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">Wing:</label>
              </b-col>
              <b-col sm="8">
                <span>
                  {{ child_venue_details.wing }}
                </span>
              </b-col>
            </b-row>
             <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">Renovated:</label>
              </b-col>
              <b-col sm="8">
                <span>
                  {{ child_venue_details.renovated }}
                </span>
              </b-col>
            </b-row>
             <b-row class="py-2 custon_border align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="lname2">Venue Category:</label>
              </b-col>
              <b-col sm="8">
               {{venue_category}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
  
        
      </b-card>

      
    </div>
</template>
  <script>
  import axios from "axios";
  
  export default {
    name: "child_venue_view",
  
    data: () => ({
      title: "child_venue_view",
     child_venue_details: [],
     venue_category: '',
     parent_id: '',
     vendor_id: '',
    }),
    components: {},
  
    methods: {
      getData() {
        this.venue_id = this.$route.params.id;
        this.vendor_id = this.$route.params.vendor_id;
        this.parent_id = this.$route.params.parent_id;
  
        axios
          .post("/admin/view_child_venue", {
            child_venue_id:this.$route.params.id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.child_venue_details = resp.data.child_venue;
                this.venue_category = resp.data.child_venue.venue_category.name;
  
              } else {
                 this.$router.push('/manage_vendors');
              }
            }
          });
      },
    },
    mounted() {
      this.getData();
    },
  };
  </script>
  <style scoped>
  .row.add-remove-button {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 10px 0;
  }
  .row.add-remove-button input {
    height: auto;
    max-width: 340px;
    flex: 1;
  }
  .row.add-remove-button button {
    background: #000;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 8px;
  }
  .form-group {
    margin: 0;
  }
  @media (max-width: 640px) {
  }
  #urls {
    max-width: 450px;
  }
  </style>-->
  <template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>Child Venue View</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item to="/manage_vendors" >View Vendors</b-breadcrumb-item>
          <b-breadcrumb-item :to="'/Manage_vendors/vendor_parent/'+vendor_id" >Parent Venue</b-breadcrumb-item>
          <b-breadcrumb-item :to="'/Manage_vendors/parent_venue/child_venue/'+parent_id+'/'+vendor_id" >Child Venue</b-breadcrumb-item>
          <b-breadcrumb-item active>View</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
        <div>
          <b-tabs content-class="mt-3" fill>
            <b-tab title="Overview" active>
              <div class="pt-4 text-center">
                <h4>
                  <u>Venue Details</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Child Venue Name:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.v_name }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Category:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.category }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Built year:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.renovated }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Guest Capacity:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.guest_room }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                 <!--  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Metting Space:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.metting_space }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col> -->
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Contact:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.contact }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                 <!--  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Contact:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.contact }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2">Wing:</label>
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.wing }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col> -->
                </b-row>
              </div>
             <!--  <div class="pt-4 text-center">
                <h4>
                  <u>Location details</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="12">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col cols="2" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Formatted address:</label
                        >
                      </b-col>
                      <b-col cols="10">
                        <span>
                          {{ overview_form.place_formatted_address }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2">City:</label>
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.city_2 }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2">State:</label>
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.state }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Country:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.country }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Pincode:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ overview_form.pincode }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div> -->
              <div class="pt-4 text-center">
                <h4>
                  <u>More Details</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="12">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col cols="2" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Details/Bio of Venue:</label
                        >
                      </b-col>
                      <b-col cols="10">
                        <span v-html="overview_form.details_bio">
                        
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                 <!--  <b-col cols="12">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col cols="2" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >NearBy Attraction:</label
                        >
                      </b-col>
                      <b-col cols="10">
                        <span>
                          {{ overview_form.near_by }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col> -->
                  <b-col cols="12">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col cols="2" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >More Details:</label
                        >
                      </b-col>
                      <b-col cols="10">
                        <span v-html="overview_form.more_info">
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="Shift Time">
              <div class="p-2">
                <b-row>
                  <b-col
                    cols="4"
                    v-for="(shift_times, index) in shift_time"
                    :key="index"
                    class="p-2"
                  >
                    <b-card
                      class="border"
                      border-variant="primary"
                      bg-variant="light"
                      text-variant="dark"
                      :header="shift_times.shift"
                      header-text-variant="dark"
                      header-tag="header"
                      header-bg-variant="light-primary"
                    >
                      <div class="d-flex">
                        <h5 class="align-middle">Date:</h5>
                        &nbsp;&nbsp;&nbsp;<span
                          >{{ shift_times.shift_sdate }} -
                          {{ shift_times.shift_edate }}</span
                        >
                      </div>
                      <div class="d-flex">
                        <h5>Time:</h5>
                        &nbsp;&nbsp;&nbsp;<span
                          >{{ shift_times.shift_stime }} -
                          {{ shift_times.shift_etime }}</span
                        >
                      </div>
                      <div class="d-flex">
                        <h5>Price:</h5>
                        &nbsp;&nbsp;&nbsp;<span>{{ shift_times.price }}</span>
                      </div>
                      <div></div>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="More Details">
              <div class="pt-4 text-center">
                <h4>
                  <u>Meeting Space</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Meeting Space:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.metting_space }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div class="pt-4 text-center">
                <h4>
                  <u>Room Details</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Type of room:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.type_of }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div class="pt-4 text-center">
                <h4>
                  <u>Size of room</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Room Size:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.room_size }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Ceiling Heignt:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.ceiling_heignt }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Room Dimension:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.room_dimension }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div class="pt-4 text-center">
                <h4>
                  <u>Set up type</u>
                </h4>
              </div>
              <div class="p-4">
                <b-row>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >U-shape:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.u_shape }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Banquate Round:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.banquate_round }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Cocktail Round:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.cocktail_round }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Theater:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.theater }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Classroom:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.classroom }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Boardroom:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.boardroom }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >E-shape:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.e_shape }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Hollow square:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.hollow_square }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Perimiter Seating:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.perimiter_seating }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Royal Conference:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.royal_conference }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >T-Shape:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.t_shape }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="py-2 custon_border align-items-center">
                      <b-col sm="4" class="text-left">
                        <label class="fw-medium mb-0" for="lname2"
                          >Talk Show:</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <span>
                          {{ more_detail.talk_show }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="Gallery View">
              <div class="p-4 event-tab">
                <b-tabs card>
                  <b-tab title="Banner" active>
                    <div class="row">
                      <div class="col-md-3"
                        v-for="(gallary_view_days, index) in gallary_view_day"
                        :key="index"
                      >
                        <div class="product-box">
                          <div class="product-image">
                            <video
                              v-if="
                                gallary_view_days.file_extension === 'mp4' ||
                                gallary_view_days.file_extension === 'mkv'
                              "
                              class="embed-responsive-item"
                              controls
                              :src="gallary_view_days.attachment"
                              :type="'video/' + gallary_view_days.file_extension"
                            ></video>
                            <img
                              :src="gallary_view_days.attachment"
                              v-if="
                                gallary_view_days.file_extension === 'png' ||
                                gallary_view_days.file_extension === 'jpeg' ||
                                gallary_view_days.file_extension === 'jpg' ||
                                gallary_view_days.file_extension === 'svg'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      </div>
                   
                    <div class="no-data" v-if="gallary_view_day.length === 0">
                      <p>No images and video are here.</p>
                    </div>
                  </b-tab>
                  <b-tab title="Thumbnail">
                    <div class="row">
                      <div class="col-md-3"
                        v-for="(gallary_view_nights, index) in gallary_view_night"
                        :key="index"
                      >
                        <div class="product-box">
                          <div class="product-image">
                             <video
                              v-if="
                                gallary_view_nights.file_extension === 'mp4' ||
                                gallary_view_nights.file_extension === 'mkv'
                              "
                              class="embed-responsive-item"
                              controls
                              :src="gallary_view_nights.attachment"
                              :type="'video/' + gallary_view_nights.file_extension"
                            ></video>
                            <img
                              :src="gallary_view_nights.attachment"
                              v-if="
                                gallary_view_nights.file_extension === 'png' ||
                                gallary_view_nights.file_extension === 'jpeg' ||
                                gallary_view_nights.file_extension === 'jpg' ||
                                gallary_view_nights.file_extension === 'svg'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      </div>
                    <div class="no-data" v-if="gallary_view_night.length === 0">
                      <p>No images and video are here.</p>
                    </div>
                  </b-tab>
                  <b-tab title="Photos">
                    <div class="row">
                      <div class="col-md-3" 
                        v-for="(gallary_view_fronts, index) in gallary_view_front"
                        :key="index"
                      >
                        <div class="product-box">
                          <div class="product-image">
                            <video
                              v-if="
                                gallary_view_fronts.file_extension === 'mp4' ||
                                gallary_view_fronts.file_extension === 'mkv'
                              "
                              class="embed-responsive-item"
                              controls
                              :src="gallary_view_fronts.attachment"
                              :type="'video/' + gallary_view_fronts.file_extension"
                            ></video>
                            <img
                              :src="gallary_view_fronts.attachment"
                              v-if="
                                gallary_view_fronts.file_extension === 'png' ||
                                gallary_view_fronts.file_extension === 'jpeg' ||
                                gallary_view_fronts.file_extension === 'jpg' ||
                                gallary_view_fronts.file_extension === 'svg'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="no-data" v-if="gallary_view_front.length === 0">
                      <p>No images and video are here.</p>
                    </div>
                  </b-tab>
                  <b-tab title="Videos">
                    <div class="row">
                      <div
                        v-for="(gallary_view_backs, index) in gallary_view_back"
                        :key="index"
                      >
                        <div class="product-box">
                          <div class="product-image">
                              <video
                              v-if="
                                gallary_view_backs.file_extension === 'mp4' ||
                                gallary_view_backs.file_extension === 'mkv'
                              "
                              class="embed-responsive-item"
                              controls
                              :src="gallary_view_backs.attachment"
                              :type="'video/' + gallary_view_backs.file_extension"
                            ></video>
                            <img
                              :src="gallary_view_backs.attachment"
                              v-if="
                                gallary_view_backs.file_extension === 'png' ||
                                gallary_view_backs.file_extension === 'jpeg' ||
                                gallary_view_backs.file_extension === 'jpg' ||
                                gallary_view_backs.file_extension === 'svg'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      </div>
                    <div class="no-data" v-if="gallary_view_back.length === 0">
                      <p>No images and video are here.</p>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </b-tab>
            <b-tab title="Amenities">
              <div class="aminities">
                <div
                  class="p-4 amenity-box"
                  v-for="(amenities, index) in amenities_data"
                  :key="index"
                >
                  <h6>{{ amenities.amenities_category }}</h6>
                  <ul>
                    <li
                      v-for="(single_amenities, index) in explodeString(
                        amenities.amenities_name
                      )"
                      :key="index"
                    >
                      {{ single_amenities }}
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="Add-On">
              <div class="p-2">
                <b-table
                  :fields="fields"
                  :items="add_on_data"
                  responsive
                  hover
                  bordered
                  show-empty
                  empty-text="No Data Available"
                  class="text-center"
                >
                  <template #cell(attachment)="data">
                    <b-img
                      :src="data.item.file_name"
                      v-if="data.item.attachment !== null"
                      style="max-width: 200px; max-height: 100px"
                    ></b-img>
                    <span v-if="data.item.attachment === null"> - </span>
                  </template>
                </b-table>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    name: "child_venue_add",
    data: () => ({
      child_venue_id: "",
      parent_id: '',
     vendor_id: '',
      overview_form: {
        v_name: "",
        city: "",
        contact: "",
        category: null,
        wing: "",
        metting_space: "",
        renovated: "",
        guest_room: "",
        dist_airport: "",
        near_by: "",
        details_bio: "",
        more_info: "",
        pincode: "",
        place_name: "",
        state: "",
        city_2: "",
        country: "",
        pin: "",
        place_formatted_address: "",
      },
      category_options: [],
      shift_time: [],
      more_detail: {
        metting_space: "",
        type_of: null,
        room_size: "",
        ceiling_heignt: "",
        u_shape: "",
        banquate_round: "",
        cocktail_round: "",
        theater: "",
        classroom: "",
        boardroom: "",
        e_shape: "",
        hollow_square: "",
        perimiter_seating: "",
        royal_conference: "",
        t_shape: "",
        talk_show: "",
        room_dimension: "",
      },
      type_of_data: [],
      gallary_view_data: [],
      gallary_view_day: [],
      gallary_view_night: [],
      gallary_view_front: [],
      gallary_view_back: [],
      day_light: 0,
      day_night: 0,
      front_view: 0,
      back_view: 0,
      amenities_data: [],
      add_on_data: [],
      fields: [
        //  'index',
        {
          key: "add_on_name",
          label: "Add On Name",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
        },
        {
          key: "publish_status",
          label: "Status",
          sortable: true,
        },
        {
          key: "category_name",
          label: "Category",
          sortable: true,
        },
        {
          key: "more_details",
          label: "Details",
          sortable: true,
        },
        {
          key: "attachment",
          label: "Image",
          sortable: false,
        },
      ],
    }),
    components: {
    },
    methods: {
      getCategoryOverview() {
        axios.post("/admin/fetch_categories").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.venue_categories.length; i++) {
                this.category_options.push({
                  value: resp.data.venue_categories[i].id,
                  text: resp.data.venue_categories[i].name,
                });
              }
            }
          }
        });
      },
      get_overview() {
        this.child_venue_id = this.$route.params.id;
        const tab_type = "overview";
        axios
          .post("/admin/fetch_details", {
            tab_type: tab_type,
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.parent_venue_id =
                  resp.data.child_venue_details[0].parent_venue_id;
                this.overview_form.v_name =
                  resp.data.child_venue_details[0].child_venue_name;
                this.overview_form.pincode =
                  resp.data.child_venue_details[0].child_venue_pincode;
                this.overview_form.city =
                  resp.data.child_venue_details[0].child_venue_city;
                this.overview_form.contact =
                  resp.data.child_venue_details[0].child_venue_contact;
                this.overview_form.wing = resp.data.child_venue_details[0].wing;
                this.overview_form.renovated =
                  resp.data.child_venue_details[0].renovated;
                this.overview_form.guest_room =
                  resp.data.child_venue_details[0].guest_rooms;
                this.overview_form.metting_space =
                  resp.data.child_venue_details[0].total_meeting_space;
                this.overview_form.dist_airport =
                  resp.data.child_venue_details[0].distance_from_airport;
                this.overview_form.more_info =
                  resp.data.child_venue_details[0].more_info;
                this.overview_form.details_bio =
                  resp.data.child_venue_details[0].child_venue_details;
                this.overview_form.near_by =
                  resp.data.child_venue_details[0].nearby_attraction;
                this.overview_form.place_formatted_address =
                  resp.data.child_venue_details[0].place_formatted_address;
                this.overview_form.place_name =
                  resp.data.child_venue_details[0].place_name;
                this.overview_form.state =
                  resp.data.child_venue_details[0].child_venue_state;
                this.overview_form.city_2 =
                  resp.data.child_venue_details[0].child_venue_city;
                this.overview_form.country =
                  resp.data.child_venue_details[0].child_venue_country;
                this.overview_form.pin =
                  resp.data.child_venue_details[0].child_venue_pincode;
  
                var category_id =
                  resp.data.child_venue_details[0].venue_category_id;
                for (var i = 0; i < this.category_options.length; i++) {
                  if (category_id === this.category_options[i].value) {
                    this.overview_form.category = this.category_options[i].text;
                  }
                }
              } else {
                this.$router.push("/venues/");
              }
            }
          });
      },
      get_shift_time() {
        const tab_type = "shift_timing";
        axios
          .post("/admin/fetch_new_timedetails", {
            tab_type: tab_type,
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
          
            for (var i = 0; i < resp.data.child_venue_details.length; i++) {
            this.shift_time.push({
                      shift_sdate: resp.data.child_venue_details[i].from_date,
                      shift_edate: resp.data.child_venue_details[i].to_date,
                      shift: resp.data.child_venue_details[i].shift_name,
                      shift_stime: resp.data.child_venue_details[i].from_time,
                      shift_etime: resp.data.child_venue_details[i].to_time,
                      price: resp.data.child_venue_details[i].price,
                      
                    });
                  }
          })
      },
      get_shift_times() {
        const tab_type = "shift_timing";
        axios
          .post("/admin/fetch_details", {
            tab_type: tab_type,
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.shift_time = [];
                var stime = "";
                var etime = "";
                var shift_type = "";
                for (var i = 0; i < resp.data.child_venue_details.length; i++) {
                  stime = new Date(
                    "1970-01-01T" +
                      resp.data.child_venue_details[i].from_time +
                      "Z"
                  ).toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                    hour12: true,
                    hour: "numeric",
                    minute: "numeric",
                  });
                  etime = new Date(
                    "1970-01-01T" + resp.data.child_venue_details[i].to_time + "Z"
                  ).toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                    hour12: true,
                    hour: "numeric",
                    minute: "numeric",
                  });
                  if (etime === "11:59 PM") {
                    etime = "12:00 AM";
                  }
                  if (stime === "11:59 PM") {
                    stime = "12:00 AM";
                  }
                  if (stime.length !== 8) {
                    stime = "0" + stime;
                  }
                  if (etime.length !== 8) {
                    etime = "0" + etime;
                  }
                  if (resp.data.child_venue_details[i].shift_type === "1") {
                    shift_type = "Morning Shift";
                  }
                  if (resp.data.child_venue_details[i].shift_type === "2") {
                    shift_type = "Afternoon Shift";
                  }
                  if (resp.data.child_venue_details[i].shift_type === "3") {
                    shift_type = "Evening Shift";
                  }
                  if (resp.data.child_venue_details[i].shift_type === "4") {
                    shift_type = "Hourly Wise";
                  }
                  if (resp.data.child_venue_details[i].to_time)
                    this.shift_time.push({
                      shift_sdate: resp.data.child_venue_details[i].from_date,
                      shift_edate: resp.data.child_venue_details[i].to_date,
                      shift: shift_type,
                      shift_stime: stime,
                      shift_etime: etime,
                      price:
                        resp.data.child_venue_details[i].price.toLocaleString(),
                    });
                }
              }
            }
          });
      },
      get_moreDetails() {
        const tab_type = "more_details";
        axios
          .post("/admin/fetch_details", {
            tab_type: tab_type,
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.more_detail.metting_space =
                  resp.data.more_details[0].meeting_space;
                this.more_detail.room_size = resp.data.more_details[0].room_size;
                this.more_detail.ceiling_heignt =
                  resp.data.more_details[0].ceiling_height;
                this.more_detail.room_dimension =
                  resp.data.more_details[0].room_dimention;
                this.more_detail.u_shape = resp.data.more_details[0].u_shape;
                this.more_detail.banquate_round =
                  resp.data.more_details[0].banquet_round;
                this.more_detail.cocktail_round =
                  resp.data.more_details[0].cocktail_round;
                this.more_detail.theater = resp.data.more_details[0].theater;
                this.more_detail.classroom = resp.data.more_details[0].classroom;
                this.more_detail.boardroom = resp.data.more_details[0].boardroom;
                this.more_detail.e_shape = resp.data.more_details[0].e_shape;
                this.more_detail.hollow_square =
                  resp.data.more_details[0].hollow_square;
                this.more_detail.perimiter_seating =
                  resp.data.more_details[0].perimeter_seating;
                this.more_detail.royal_conference =
                  resp.data.more_details[0].royal_conf;
                this.more_detail.t_shape = resp.data.more_details[0].t_shape;
                this.more_detail.talk_show = resp.data.more_details[0].talk_show;
  
                var category_ids = resp.data.more_details[0].setup_type_id;
                for (var i = 0; i < this.type_of_data.length; i++) {
                  if (category_ids === this.type_of_data[i].value) {
                    this.more_detail.type_of = this.type_of_data[i].text;
                  }
                }
              }
            }
          });
      },
      get_typeofRoom() {
        axios.get("/admin/get_setup_type").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.setup_type.length; i++) {
                this.type_of_data.push({
                  value: resp.data.setup_type[i].id,
                  text: resp.data.setup_type[i].type_name,
                });
              }
            }
          }
        });
      },
      get_gallery_data() {
        const tab_type = "gallery_view";
        axios
          .post("/admin/fetch_details", {
            tab_type: tab_type,
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                var path = resp.data.path;
                this.gallary_view_data = resp.data.gallery_view_details;
                this.gallary_view_day = [];
                this.gallary_view_night = [];
                this.gallary_view_front = [];
                this.gallary_view_back = [];
  
                for (var a = 0; a < this.gallary_view_data.length; a++) {
                  this.gallary_view_data[a].attachment =
                    path + this.gallary_view_data[a].attachment;
                  if (this.gallary_view_data[a].image_type === "1") {
                    this.gallary_view_day.push(this.gallary_view_data[a]);
                  }
                  if (this.gallary_view_data[a].image_type === "2") {
                    this.gallary_view_night.push(this.gallary_view_data[a]);
                  }
                  if (this.gallary_view_data[a].image_type === "3") {
                    this.gallary_view_front.push(this.gallary_view_data[a]);
                  }
                  if (this.gallary_view_data[a].image_type === "4") {
                    this.gallary_view_back.push(this.gallary_view_data[a]);
                  }
                }
                this.day_light++;
                this.day_night++;
                this.front_view++;
                this.back_view++;
              }
            }
          });
      },
      get_amenities() {
        axios
          .post("/admin/view_amenities", {
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.amenities_data = resp.data.amenities;
              }
            }
          });
      },
      explodeString(theText) {
        return theText.split(",");
      },
      get_addOn() {
        axios
          .post("/admin/view_addon", {
            child_venue_id: this.child_venue_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                var path = resp.data.path;
                var add_on = resp.data.add_on;
                var publish_status = "";
                for (var i = 0; i < add_on.length; i++) {
                  if (add_on[i].publish_status === "1") {
                    publish_status = "Publish";
                  } else {
                    publish_status = "Unpublish";
                  }
                  this.add_on_data.push({
                    add_on_name: add_on[i].add_on_name,
                    attachment: JSON.parse(add_on[i].attachment),
                    file_name: path + JSON.parse(add_on[i].attachment),
                    more_details: add_on[i].more_details,
                    price: "Rs." + " " + add_on[i].price,
                    publish_status: publish_status,
                    category_name: add_on[i].has_category.name,
                  });
                }
              }
            }
          });
      },
    },
    mounted() {
      this.venue_id = this.$route.params.id;
      this.vendor_id = this.$route.params.vendor_id;
      this.parent_id = this.$route.params.parent_id;

      this.getCategoryOverview();
      this.get_typeofRoom();
      this.get_overview();
      this.get_shift_time();
      this.get_moreDetails();
      this.get_gallery_data();
      this.get_amenities();
      this.get_addOn();
    },
    computed: {},
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style scoped>
  .row.add-remove-button {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 10px 0;
  }
  .row.add-remove-button input {
    height: auto;
    max-width: 340px;
    flex: 1;
  }
  .row.add-remove-button button {
    background: #000;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 8px;
  }
  .form-group {
    margin: 0;
  }
  @media (max-width: 640px) {
  }
  #pd {
    padding: 10px;
  }
  
  #e_message {
    color: red;
  }
  #renovated {
    border-color: rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .card-header:first-child {
    border-radius: 4px 4px 0 0;
    font-size: 20px;
    padding: 9px;
    font-weight: 600;
  }
  </style>